import React from 'react';
import App from '../components/App';
import SEO from '../components/Seo';
import Desktop from '../components/Desktop/Desktop';

const IndexPage = () => (
    <App>
        <SEO title="Home" />
        <Desktop />
    </App>
);

export default IndexPage;
